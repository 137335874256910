import React, { useState, useEffect, useMemo } from "react"
import Styles from "../styles/video_comparison_for_webview.module.scss";
import Video from "../components/Video";
import VideoSelectButton from '../components/VideoSelectButton';
// import VideoListManagement from "../components/VideoListManagement"
import VideoComparisonModal from "../templates/VideoComparisonModal"
import GeneralButton from "../components/atoms/GeneralButton"


const VideoComparisonPage = () => {

    const [ videoList, setVideoList ] = useState( [ "", "", "" ] );
    const [ selectedThumbnailList, setSelectedThumbnailList ] = useState( [] );
    const [ videoComparisonModalVisible, setVideoComparisonModalVisible ] = useState( false );

    const handleVideoSelectSwitch = ( action: any, video: any ) => {
        let newSelectedThumbnailList
        if ( action === "select" ) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            selectedThumbnailList.push( video.file )
            newSelectedThumbnailList = selectedThumbnailList
        }
        else if ( action === "release" ) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            let releaseVideoIndex = selectedThumbnailList.indexOf( video.file )
            newSelectedThumbnailList = selectedThumbnailList.filter( ( video, index ) => index !== releaseVideoIndex )
        }
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        setSelectedThumbnailList( newSelectedThumbnailList.slice() )
    }

    const [ video0, setVideo0 ] = useState<string | null>( null )
    const [ video1, setVideo1 ] = useState<string | null>( null )

    const fixBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflow = "hidden"
        document.getElementsByTagName( "body" )[ 0 ].style.overflow = "hidden"
    }

    const releaseBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflow = "auto"
        document.getElementsByTagName( "body" )[ 0 ].style.overflow = "auto"
    }


    useEffect( () => {
        fixBackground()
        return () => releaseBackground()
    }, [] )

    const chnageVideoURL = ( newURL: any, changeVideoIndex: any ) => {
        const newVideoList = videoList.map( ( URL, index ) =>
            index === changeVideoIndex ?
                newURL :
                URL
        )
        setVideoList( newVideoList.slice() )
    }

    return (
        <div>
            {/* { videoComparisonModalVisible &&
                <VideoComparisonModal
                    video0={ video0 }
                    video1={ video1 }
                    setVideoComparisonModalVisible={ setVideoComparisonModalVisible }
                    setCommentFormVisible={ () => { } } /> } */}
            <div>
                <h2>動画リスト</h2>
                { videoList.map( ( videoURL, index ) =>
                    <VideoSelectComponent
                        URL={ videoURL }
                        setURL={ ( value: any ) => chnageVideoURL( value, index ) } />
                ) }

            </div>
        </div>
    );
}

const VideoSelectComponent = ( {
    URL,
    setURL
}: any ) => {

    return (
        <div onClick={ () => { } }>
            <input
                type="text"
                value={ URL }
                onChange={ ( e ) => setURL( e.target.value ) } />
            {/* <GeneralButton title="ファイルを選択" onClick={ () =>} /> */ }
            <VideoSelectButton onChangeVideoFile={ setURL } />
        </div>
    )
}

export default VideoComparisonPage
